export function getCookieValue(cookieName: string): string {
  var start, end;
  if (document.cookie.length > 0) {
    start = document.cookie.indexOf(cookieName + "=");
    if (
      start !== -1 &&
      (start === 0 || document.cookie.charAt(start - 1) === " ")
    ) {
      start += cookieName.length + 1;
      end = document.cookie.indexOf(";", start);
      if (end === -1) {
        end = document.cookie.length;
      }
      return window.unescape(document.cookie.substring(start, end));
    }
  }
  return "";
}

export function setCookie(
  name: string,
  value: string,
  expires: number,
  path: string,
  domain: string,
  secure?: string
) {
  // set time, it's in milliseconds
  var today = new Date();
  today.setTime(today.getTime());

  /*
    if the expires variable is set, make the correct
    expires time, the current script below will set
    it for x number of days, to make it for hours,
    delete * 24, for minutes, delete * 60 * 24
    */
  if (expires) {
    expires = expires * 1000 * 60 * 60;
  }

  var expires_date = new Date(today.getTime() + expires);

  document.cookie =
    name +
    "=" +
    escape(value) +
    (expires ? ";expires=" + expires_date.toUTCString() : "") +
    (path ? ";path=" + path : "") +
    (domain ? ";domain=" + domain : "") +
    (secure ? ";secure" : "");
}

export function removeCookie(cookie_name: string): void {
  var cookie_date = new Date(); // current date & time
  cookie_date.setTime(cookie_date.getTime() - 1);
  document.cookie = cookie_name += "=; expires=" + cookie_date.toUTCString();
}
