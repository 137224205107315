import * as Primer from "./primer";

interface WindowDotPortal {
  /**
   * The URL to the Customer Portal host from which this Primer HTML was fetched.
   * @example "https://access.qa.redhat.com"
   */
  host: string;

  /**
   * The URL to the Red Hat SSO server that should be used in this environment.
   * @example "https://sso.qa.redhat.com"
   */
  idp_url: string;

  /**
   * The code for the currently active locale.
   * @example one of: "en", "fr", "ja", "ko", "zh_CN"
   */
  lang: string;

  /**
   * The version of the Primer package that's currently deployed.
   * @example "1.0.0"
   */
  version: string;

  /**
   * A timestamp indicating when this build of Primer took place.
   * @example "Thu Jun 03 2021 15:10:19 GMT-0400 (Eastern Daylight Time)"
   */
  builddate: string;

  /**
   * A timestamp for when the Primer HTML was deployed.
   *
   * Local to the Apache server serving the HTML response.  Resolved by Apache SSI.
   *
   * @example "Thu, 03 Jun 2021 15:08:52 EDT-0400"
   */
  deploydate: string;

  /**
   * A timestamp for when the Primer HTML was fetched.
   *
   * Local to the Apache server serving the HTML response.  Resolved by Apache SSI.
   *
   * @example "Thu, 03 Jun 2021 15:08:52 EDT-0400"
   */
  fetchdate: string;

  /**
   * A reference to the main Primer JS module.
   *
   * @example `window.primer.pfe.include("pfe-cta")`
   */
  primer: typeof Primer;
}

declare global {
  // add types for window.portal
  interface Window {
    portal: WindowDotPortal;
  }
}
