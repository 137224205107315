///////////////
//  Imports  //
///////////////

import "./window.portal";
import { exportGlobal } from "./export-global";
import session from "./session/session";
import * as nav from "./nav";
import * as network from "./primer-network";
import analytics from "./analytics/main";
import {reporter} from "./analytics/reporter.js";
import * as toTop from "./to-top";
import env from "./target-env";

///////////////
//  Exports  //
///////////////

export * as components from "./components";
export { onReady } from "./primer-ready";
export { dnt } from "./dnt";
export { default as session } from "./session/session";
export { reporter };
export * as locale from "./locale";

// attach Primer module to window.portal.primer
exportGlobal();

///////////////////////////////////////////
//  Wait, then load low-priority stuff.  //
///////////////////////////////////////////

// setTimeout((): void => {
//   // load the status widget that shows up in the footer
//   if (network.online) {
//     import("./status-widget.js").then(({ init }) => {
//       init({ statusPageId: "dn6mqn7xvzz3" });
//     });
//   }
// }, 2000);

// @ts-ignore -> add a reference for old paf stuff to look at portal.analytics for attribute scraping
// if (window.portal) {
//   window.portal.analytics = analytics;
// }

if (network.online) {
  session.init();
  nav.init();
  reporter.autoReport();
}

toTop.init();
