var DEFAULT = "en";
var data_attr = "data-lang";
var locale_picker_options = Array.from(
  document.querySelectorAll("#localesMenu a[" + data_attr + "]")
);
var names = {
  en: "English",
  fr: "Français",
  ko: "한국어",
  ja: "日本語",
  zh_CN: "中文 (中国)",
};

function closest_locale(locale) {
  var closest = DEFAULT;
  var supported = supported_locales();
  var is_supported = supported.indexOf(locale) >= 0;
  var left_part = locale.split("_")[0];
  var left_supported = supported.indexOf(left_part) >= 0;

  if (is_supported) {
    closest = locale;
  } else if (left_supported) {
    closest = left_part;
  }

  return closest;
}

function supported_locales() {
  var pickers = locale_picker_options.map(data_lang);
  return pickers;
}

function data_lang(el, i) {
  return el.getAttribute(data_attr);
}

/**
 * Gets language name from laguage code
 * Returns Language name in native language
 **/
function name(lang: string) {
  return names[lang];
}

var lang_api = {
  default: DEFAULT,
  closest: closest_locale,
  supported: supported_locales,
  name: name,
};

export default lang_api;
