/**
 * Wire up the "scroll to top" link to appear after the user has scrolled down a bit.
 */
export function init() {
  // Scroll to top button
  let to_top_link = document.querySelector('#to-top a');
  window.addEventListener("scroll", scroll, { passive: true });
  function scroll() {
    let scrollTop = window.pageYOffset;
    if (scrollTop > 300) {
      to_top_link.style.opacity = 1;
    }
    else {
      to_top_link.style.opacity = 0;
    }
  }
  // run it once so the "scroll to top" link shows up if you load the page partway down.
  scroll();
}
