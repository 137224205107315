import { session } from "../primer";
import { ReporterEvent } from "@chapeaux/cpx-eddl";

class Reporter {
  constructor() {
    if (typeof document.documentElement.dataset.eddloff === 'undefined') {
      // .pafoff found on html tag - they're handling analytics
    } else {
      this.active = false;
    }
  }
  _active = true;
  get active() {
    return this._active;
  }
  set active(val) {
    this._active = !!val;
  }

  async generateHash(txt) {
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const encoder = new TextEncoder();
    const hash = await crypto.subtle.digest('SHA-256', encoder.encode(txt));
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2,'0')).join('');
    return hashHex;
  }

  report(evtName, data) {
    if (typeof evtName !== 'undefined') {
      const rep = new ReporterEvent(evtName, data);
      dispatchEvent(rep);
    }
  }

  autoReport() {
    if (this.active) {
      const defaultPageCategory = (new URL(window.location.href)).pathname.substring(1).split('/')[0];
      const dataAttrs = [...Object.keys(document.documentElement.dataset)].reduce((a,c)=> { 
        const keyTxt = c.substring(c.length-4);
        if (keyTxt==='Eddl') {
          const keyVal = c.substring(0,c.length-4).replace(/(id|url)$/gmi, v=>v.toUpperCase())
          a[c.substring(0,c.length-4)] = document.documentElement.dataset[c];
        }
        return a;
      },{
        siteName:'cp',
        siteLanguage: 'en',
        dataObject:'appEventData',
        destinationURL: window.location.href,
        gated: 'false',
        pageCategory: defaultPageCategory,
        pageTitle: `${document.title || ""}`,
        
      });
      this.report('Page Load Started', dataAttrs);
      session.onInit(async (session) => {
        if (!session.isAuthenticated()) {
          this.report('User Detected', {
            ebsAccountNumber: "",
            userID: "",
            lastLoginDate: "",
            loggedIn: "false",
            organizationID: "",
            socialAccountsLinked: "",
            ssoClientID: "",
            hashedEmail: ""
          });
        } else {
          let user = session.getUserInfo();
          let token = session.getToken();
          this.report('User Detected', {
            ebsAccountNumber: `${user.account_id || ""}`,
            hashedEmail: `${await this.generateHash(token.email) || ""}`,
            lastLoginDate: `${token.auth_time ? (new Date(token.auth_time*1000)).toISOString() : ''}`, 
            loggedIn: `${session.isAuthenticated()}`,
            organizationID: `${user.organizationID || ""}`,
            ssoClientID: `${token.aud || ""}`,
            userID: `${user.user_id || ""}`
          });
        }
        this.report('Page Load Completed', undefined);
      });
    }
  }
}

export const reporter = new Reporter();
