/**
 * Returns whether or not the user has enabled their browser's Do Not Track setting.
 *
 * @module dnt
 * @author Michael Clayton <mclayton@redhat.com>
 * @copyright Red Hat 2017
 */
export const dnt: boolean =
  window.doNotTrack == "1" ||
  navigator.doNotTrack == "1" ||
  navigator.msDoNotTrack == "1" ||
  navigator.doNotTrack == "yes";

// add doNotTrack types for various old and medium-old browsers that put the
// value in places other than navigator.doNotTrack
declare global {
  interface Window {
    doNotTrack: typeof navigator.doNotTrack;
  }
  interface Navigator {
    msDoNotTrack: typeof navigator.doNotTrack;
  }
}
