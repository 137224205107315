/**
 * A variable injected at build-time that indicates offline mode.  If true, Primer is being build with NO support for remote network requests.
 * @see Primer's esbuild options, `define` specifically
 */
declare const PRIMER_OFFLINE: boolean;

/**
 * A variable injected at build-time that indicates online mode.  If true, Primer is being build with support for remote network requests.
 * @see Primer's esbuild options, `define` specifically
 */
declare const PRIMER_ONLINE: boolean;

/** Is Primer being built for online use. */
export let online = PRIMER_ONLINE;
/** Is Primer being built for offline use (project Mimir). */
export let offline = PRIMER_OFFLINE;
