const storage = window.localStorage;

export function get(key: string): string {
  var value = storage.getItem(key);
  return value && JSON.parse(value);
}

export function set(key: string, val: string): string | void {
  if (typeof val !== "undefined") {
    return storage.setItem(key, JSON.stringify(val));
  }
}

export function remove(key: string): void {
  storage.removeItem(key);
}
