// This module is responsible for exporting the Primer module to a global
// location, for compatibility with legacy module systems.
import "./window.portal";
import * as Primer from "./primer";
import { dispatchReadyEvent } from "./primer-ready";

/**
 * Export the Primer module to window.portal.primer.
 */
export function exportGlobal(): void {
  // add this module's exports to window.portal.primer for consumption by
  // legacy module systems
  if (window.portal) {
    window.portal.primer = Primer;
  }
  dispatchReadyEvent();
}
