import "./window.portal";

const globalEventName = "cp_primer_global_ready";

let isReady: boolean = false;

type PrimerModule = typeof window.portal.primer;

type OnReadyFunction = (primer: typeof window.portal.primer) => void;

/**
 * Register a callback to be run when Primer is ready. The callback will be run
 * when Primer is initialized. If Primer is already initialized, the callback
 * will be run immediately.
 *
 * Primer being initialized does not mean all sub-modules are initialized.  For
 * example, `session` has its own `onInit` function that must be used before .
 *
 * @example `window.portal.primer.onReady().then( primer => primer.pfe.include("pfe-card") )`
 */
export function onReady(): Promise<PrimerModule> {
  return new Promise((resolve) => {
    if (isReady) {
      resolve(window.portal.primer);
    } else {
      window.addEventListener(globalEventName, () => {
        resolve(window.portal.primer);
      });
    }
  });
}

/**
 * Dispatch the ready event to indicate that the Primer module is ready to be referenced.
 */
export function dispatchReadyEvent(): void {
  isReady = true;

  // trigger an event to indicate that window.portal.primer is ready
  // this is primarily used for module interop, since ES5-era module systems
  // (CJS, AMD, IIFE, globals, all of 'em) can't import Primer's ES module,
  // but they can listen for an event.
  window.dispatchEvent(
    new CustomEvent(globalEventName, {
      bubbles: false, // this event only occurs on window
    })
  );
}
