import PFElement from "@patternfly/pfelement/dist/pfelement";
import * as network from "./primer-network";

// /**
//  * The object of supported elements maps PFE tag names to functions that import
//  * that element's module.
//  */
// interface SupportedElementRegistry {
//   [element: string]: () => Promise<PFElement>;
// }

//prettier-ignore
const registry = {
  "pfe-accordion"          : () => import("@patternfly/pfe-accordion/dist/pfe-accordion.js"),
  "pfe-autocomplete"       : () => import("@patternfly/pfe-autocomplete/dist/pfe-autocomplete.js"),
  "pfe-avatar"             : () => import("@patternfly/pfe-avatar/dist/pfe-avatar.js"),
  "pfe-badge"              : () => import("@patternfly/pfe-badge/dist/pfe-badge.js"),
  "pfe-band"               : () => import("@patternfly/pfe-band/dist/pfe-band.js"),
  "pfe-button"             : () => import("@patternfly/pfe-button/dist/pfe-button.js"),
  "pfe-card"               : () => import("@patternfly/pfe-card/dist/pfe-card.js"),
  "pfe-clipboard"          : () => import("@patternfly/pfe-clipboard/dist/pfe-clipboard.js"),
  "pfe-codeblock"          : () => import("@patternfly/pfe-codeblock/dist/pfe-codeblock.js"),
  "pfe-collapse"           : () => import("@patternfly/pfe-collapse/dist/pfe-collapse.js"),
  "pfe-content-set"        : () => import("@patternfly/pfe-content-set/dist/pfe-content-set.js"),
  "pfe-cta"                : () => import("@patternfly/pfe-cta/dist/pfe-cta.js"),
  "pfe-datetime"           : () => import("@patternfly/pfe-datetime/dist/pfe-datetime.js"),
  "pfe-dropdown"           : () => import("@patternfly/pfe-dropdown/dist/pfe-dropdown.js"),
  "pfe-health-index"       : () => import("@patternfly/pfe-health-index/dist/pfe-health-index.js"),
  "pfe-icon"               : () => import("@patternfly/pfe-icon/dist/pfe-icon.js"),
  "pfe-icon-panel"         : () => import("@patternfly/pfe-icon-panel/dist/pfe-icon-panel.js"),
  "pfe-jump-links"         : () => import("@patternfly/pfe-jump-links/dist/pfe-jump-links.js"),
  "pfe-markdown"           : () => import("@patternfly/pfe-markdown/dist/pfe-markdown.js"),
  "pfe-modal"              : () => import("@patternfly/pfe-modal/dist/pfe-modal.js"),
  "pfe-number"             : () => import("@patternfly/pfe-number/dist/pfe-number.js"),
  "pfe-page-status"        : () => import("@patternfly/pfe-page-status/dist/pfe-page-status.js"),
  "pfe-primary-detail"     : () => import("@patternfly/pfe-primary-detail/dist/pfe-primary-detail.js"),
  "pfe-progress-indicator" : () => import("@patternfly/pfe-progress-indicator/dist/pfe-progress-indicator.js"),
  "pfe-progress-steps"     : () => import("@patternfly/pfe-progress-steps/dist/pfe-progress-steps.js"),
  "pfe-readtime"           : () => import("@patternfly/pfe-readtime/dist/pfe-readtime.js"),
  "pfe-select"             : () => import("@patternfly/pfe-select/dist/pfe-select.js"),
  "pfe-tabs"               : () => import("@patternfly/pfe-tabs/dist/pfe-tabs.js"),
  "pfe-toast"              : () => import("@patternfly/pfe-toast/dist/pfe-toast.js"),
  "cp-comments"            : () => import("@cpelements/cp-comments"),
  "cp-404"                 : () => import("@cpelements/cp-404"),
  "cp-search-autocomplete" : () => import("@cpelements/cp-search-autocomplete"),
  "rh-table"               : () => import("@cpelements/rh-table"),
  /*
    We decided not to include these elements because they shouldn't be included by anything other than Primer™
  */
  // "pfe-navigation"         : () => import("@patternfly/pfe-navigation/dist/pfe-navigation.js"),
  // "pfe-navigation"         : () => import("@cpelements/pfe-navigation"),
  // "rh-account-dropdown"    : () => import("@cpelements/rh-account-dropdown"),
  // "rh-site-switcher"       : () => import("@cpelements/rh-site-switcher"),
};

/** The PatternFly Element tag names supported by Primer. */
export type SupportedElements = keyof typeof registry;

/**
 * Include a PatternFly Element by component name.
 *
 * @example pfe.include("pfe-toast");
 * @example // get a reference
 * const PfeIcon = await pfe.include("pfe-icon");
 * console.log("PfeIcon version:", PfeIcon.version);
 */
export function include(
  name: SupportedElements
): Promise<CustomElementConstructor> {
  // Add check for customElement registry
  const registeredComponent = customElements.get(name);
  if (registeredComponent) {
    return new Promise((resolve) => resolve(registeredComponent));
  }

  if (name in registry) {
    return registry[name]().then(() => customElements.get(name));
  } else {
    throw new Error(
      `[primer.components.include] element "${name}" cannot be loaded because it's not a supported element`
    );
  }
}
